/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'clock': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 100',
    data: '<switch><g><path pid="0" d="M5273.1 2400.1v-2c0-2.8-5-4-9.7-4s-9.7 1.3-9.7 4v2c0 1.8.7 3.6 2 4.9l5 4.9c.3.3.4.6.4 1v6.4c0 .4.2.7.6.8l2.9.9c.5.1 1-.2 1-.8v-7.2c0-.4.2-.7.4-1l5.1-5c1.3-1.3 2-3.1 2-4.9zm-9.7-.1c-4.8 0-7.4-1.3-7.5-1.8.1-.5 2.7-1.8 7.5-1.8s7.3 1.3 7.5 1.8c-.2.5-2.7 1.8-7.5 1.8z"/><path pid="1" d="M5268.4 2410.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1h-4.3zM5272.7 2413.7h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1zM5272.7 2417h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1 0-.5-.4-1-1-1z"/><g><path pid="2" d="M50 2.5C23.8 2.5 2.5 23.8 2.5 50S23.8 97.5 50 97.5 97.5 76.2 97.5 50 76.2 2.5 50 2.5zM50 88c-20.9 0-38-17-38-38s17-38 38-38 38 17 38 38-17.1 38-38 38z"/><path pid="3" d="M68.6 53l-13.9-6.1V25.5c0-2.6-2.1-4.8-4.8-4.8s-4.8 2.1-4.8 4.8V50c0 1.9 1.1 3.6 2.8 4.4l16.7 7.3c.6.3 1.3.4 1.9.4 1.8 0 3.6-1.1 4.4-2.8 1.2-2.5.1-5.3-2.3-6.3z"/></g></g></switch>'
  }
})
