/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flag-it': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<g fill-rule="evenodd" stroke-width="1pt"><path pid="0" _fill="#fff" d="M0 0h640v480H0z"/><path pid="1" _fill="#009246" d="M0 0h213.3v480H0z"/><path pid="2" _fill="#ce2b37" d="M426.7 0H640v480H426.7z"/></g>'
  }
})
