<script>
import Vue from 'vue'

import { VueperSlides, VueperSlide } from 'vueperslides'
import SvgIcon from 'vue-svgicon'
import VueLazyLoad from 'vue-lazyload'
import LightBox from 'vue-image-lightbox'
import './components/icons'
import {Checkbox, Dropdown, Field, Input, Select, Toast, Upload} from 'buefy'
import Storage from 'vue-ls'
// import CookieLaw from 'vue-cookie-law'

import PromoSlider from './components/PromoSlider.vue'
import ProductSlider from './components/ProductSlider.vue'
import ProductPhotos from './components/ProductPhotos.vue'
import Topbar from './components/Topbar.vue'
import ContactForm from './components/ContactForm.vue'
import Announcement from './components/Announcement.vue'
import CookieBar from './components/CookieBar.vue'

Vue.use(SvgIcon, {tagName: 'svgicon'})
Vue.use(VueLazyLoad)
Vue.use(Storage, {name: 'ls', storage: 'local'});

Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(Field)
Vue.use(Input)
Vue.use(Select)
Vue.use(Toast)
Vue.use(Upload)

Vue.component(PromoSlider.name, PromoSlider)
Vue.component(ProductSlider.name, ProductSlider)
Vue.component(ProductPhotos.name, ProductPhotos)
Vue.component(Topbar.name, Topbar)
Vue.component(ContactForm.name, ContactForm)
Vue.component(Announcement.name, Announcement)
Vue.component(CookieBar.name, CookieBar)
Vue.component('vueper-slides', VueperSlides)
Vue.component('vueper-slide', VueperSlide)
Vue.component('vue-image-lightbox', LightBox)


export default {
  name: 'app',
  data: () => ({
  }),
};
</script>

<style lang="scss">
@import "~vueperslides/dist/vueperslides.css";
@import "~vue-image-lightbox/dist/vue-image-lightbox.min.css";
@import "./style.scss";
</style>
