
<template>
  <transition appear :name="transitionName">
    <div class="Cookie" :class="[containerPosition, cookieTheme]" v-if="isOpen">
      <slot :accept="accept" :close="close" :open="open">
        <div class="Cookie__content">
          <slot name="message">{{ message }}</slot>
        </div>
        <div class="Cookie__buttons">
          <a :target="target" :href="buttonLink" v-if="externalButtonLink" :class="buttonClass">{{ buttonLinkText }}</a>
          <router-link :to="buttonLink" v-if="internalButtonLink" :class="buttonClass">{{ buttonLinkText }}</router-link>
          <button :class="buttonClass" @click="accept">{{ buttonText }}</button>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
// Based on: https://github.com/apertureless/vue-cookie-law
export default {
  name: 'cookie-bar',

  props: {
    buttonText: {
      type: String,
      default: 'Got it!'
    },
    buttonLink: {
      type: [String, Object],
      required: false
    },
    buttonLinkText: {
      type: String,
      default: 'More info'
    },
    buttonLinkNewTab: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'base'
    },
    /**
     * Cookie Container position
     * bottom, top
     * @type {Object}
     */
    position: {
      type: String,
      default: 'bottom'
    },
    /**
     * Transition name has following possibilities
     * slideFromBottom
     * slideFromTop
     * fade
     * @type {Object}
     */
    transitionName: {
      type: String,
      default: 'slideFromBottom'
    },
    buttonClass: {
      type: String,
      default: 'Cookie__button'
    },
    storageName: {
      type: String,
      default: 'cookie:accepted'
    },
    cookieOptions: {
      type: Object,
      default: () => {},
      required: false
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
    containerPosition () {
      return `Cookie--${this.position}`
    },
    cookieTheme () {
      return `Cookie--${this.theme}`
    },
    externalButtonLink () {
      return typeof this.buttonLink === 'string' && this.buttonLink.length
    },
    internalButtonLink () {
      return typeof this.buttonLink === 'object' && this.buttonLink != null && Object.keys(this.buttonLink).length
    },
    target () {
      return this.buttonLinkNewTab ? '_blank' : '_self'
    },
  },

  created () {
    if (!this.getVisited() === true) {
      this.isOpen = true
    }
  },

  methods: {
    setVisited () {
      localStorage.setItem(this.storageName, true)
    },
    getVisited () {
      return localStorage.getItem(this.storageName)
    },
    accept () {
      this.setVisited()
      this.isOpen = false
      this.$emit('accept')
    },
    close () {
      this.isOpen = false
    },
    open () {
      if (!this.getVisited() === true) {
        this.isOpen = true
      }
    }
  }
}
</script>

<style lang="scss">
@import "~bulma/sass/utilities/_all";

.Cookie {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
  > * {
    margin: 0 4px;
    align-self: center;
  }
  flex-flow: row;
}
.Cookie--bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.Cookie__buttons {
  display: flex;
}
.Cookie__button {
  cursor: pointer;
  align-self: center;
  white-space: nowrap;
}

.Cookie {
    background: rgba(60, 60, 60, 0.9);
    color: #fff;
    padding: 10px;

    .Cookie__button {
      background: #E76A68;
      padding: 0.625em 1.0rem;
      color: #fff;
      border-radius: 0;
      border: 0;
      font-size: 1.0rem;
      &:hover {
        background: rgb(163, 75, 74);
      }
  }
}

@include from($desktop) {
.Cookie {
  .Cookie__button {
      padding: 0.625em 3.125em;
  }
}
}

.slideFromBottom-enter, .slideFromBottom-leave-to {
  transform: translate(0px, 12.500em);
}
.slideFromBottom-enter-to, .slideFromBottom-leave {
  transform: translate(0px, 0px);
}
.slideFromBottom-enter-active,
.slideFromBottom-leave-active{
  transition: transform .4s ease-in;
}
</style>