<script>
export default {
  name: "contact-form",
  data: () => ({
    name: '',
    email: '',
    phone: '',
    message: '',
    file: null,
  }),
}
</script>