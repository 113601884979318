/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flag-de': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<path pid="0" _fill="#ffce00" d="M0 320h640v160H0z"/><path pid="1" d="M0 0h640v160H0z"/><path pid="2" _fill="#d00" d="M0 160h640v160H0z"/>'
  }
})
