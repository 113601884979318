/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkmark': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M41.314 68.056C51.796 50.602 68.768 25.485 83.507 15l.993 15.947C60.672 46.894 53.228 63.073 42.307 85 35.357 75.033 22.45 69.053 15.5 64.07c2.485-2.76 5.957-6.977 8.936-9.967 3.971 1.993 12.907 8.97 16.878 13.953z" overflow="visible" color="#000"/>'
  }
})
