<template>
  <vueper-slides
        ref="promoslider"
        :infinite="true"
        :dragging-distance="100"
        :autoplay="true"
        :transition-speed="1000"
        :breakpoints="breakpoints"
        fixed-height="600px"
        class="promo-slider">
    <vueper-slide
      v-for="(slide, i) in slides" :key="i"
      :content="slide.content" :title="slide.title" :image="slide.image">
    </vueper-slide>
  </vueper-slides>
</template>

<script>
export default {
  name: "promo-slideshow",

  data: () => ({
    slides: [],

    breakpoints: {
      1024: {
        fixedHeight: "400px",
        arrows: false,
      },
      768: {
        fixedHeight: "200px",
        arrows: false,
      },
    },
  }),

  mounted() {
    // Load photos
    var d = JSON.parse(document.getElementById('json_data').textContent)
    this.slides = d.promo_photos
    this.$refs.promoslider.resumeAutoplay()
  },
}
</script>