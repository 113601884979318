/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flag-ru': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<g fill-rule="evenodd" stroke-width="1pt"><path pid="0" _fill="#fff" d="M0 0h640v480H0z"/><path pid="1" _fill="#0039a6" d="M0 160h640v320H0z"/><path pid="2" _fill="#d52b1e" d="M0 320h640v160H0z"/></g>'
  }
})
