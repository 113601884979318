/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chili': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 100',
    data: '<path pid="0" d="M22.99 73.44c-1.27-.07-3.2-.25-4.16-.39-2.31-.33-5.13-1-6.2-1.48-2.1-.93-4.1-3.24-4.1-4.76 0-.53.27-1 .74-1.27 1.02-.6 4.05-1.53 10-3.07 2.24-.58 4.73-1.25 5.55-1.49 10.1-2.97 17.24-7.36 25.3-15.56a90.8 90.8 0 0 0 7.47-8.55c2.16-2.77 3.9-4.09 6.62-5 1.5-.5 2.64-.68 4.37-.67 1.28 0 2.56.13 2.7.27.07.07-.24.59-.48.81-.58.53-1.1.66-3.59.93-2.64.3-3.03.37-3.35.7-.48.48-.48 1.09 0 1.57.21.2 1.11.55 4.1 1.55 2.7.9 3.83 1.31 3.8 1.4a95.3 95.3 0 0 1-1.85 2.67c-1.92 2.73-2.04 2.97-1.73 3.58.18.34.6.6.98.6.12 0 1.85-.6 3.84-1.32 2.49-.92 3.62-1.3 3.67-1.21.12.22.47 1.81.63 2.88.1.64.2 2.14.23 3.63.06 2.15.1 2.57.24 2.79.22.34.52.5.98.5.68 0 .82-.2 1.88-2.4 1.6-3.34 1.81-3.73 1.85-3.5.04.31-.26 1.81-.55 2.73a13.7 13.7 0 0 1-3.75 5.86c-4.09 3.52-12.6 8.17-20.98 11.47-9.93 3.9-19.72 6.2-28.57 6.7-1.59.08-4.32.1-5.64.03z" _fill="red"/><path pid="1" d="M79.68 45.81c-.15-1.94-.93-4.94-1.44-5.5-.47-.52-.7-.49-3.42.51-1.35.5-2.46.9-2.47.88-.02-.01.37-.58.85-1.26 1.83-2.56 1.88-2.68 1.48-3.34-.21-.35-.32-.4-3-1.28-.97-.32-1.76-.6-1.76-.64 0-.04.17-.1.38-.13a4.42 4.42 0 0 0 2.97-2.36c.23-.6.22-.6 1.3-.13a14.9 14.9 0 0 1 2.99 1.78c.74.58 1.17.7 1.62.42.11-.06.74-.67 1.39-1.34a37.43 37.43 0 0 1 7.6-5.93c.82-.5 1.52-.92 1.56-.92.08 0 1.71 3.08 1.66 3.12-.02 0-.5.17-1.07.34a22.5 22.5 0 0 0-6.1 3.13 51.1 51.1 0 0 0-3.86 3.06c-.28.4-.18.99.3 1.82a15.03 15.03 0 0 1 1.67 4.77c.06.44.06.45-.37.7-.53.33-1.09 1.06-1.72 2.26l-.49.92z" _fill="green"/>'
  }
})
