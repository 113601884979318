/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'budapest': {
    width: 16,
    height: 16,
    viewBox: '0 0 70 20',
    data: '<defs><linearGradient id="svgicon_budapest_a" x1="0" x2="0" y1="0" y2="100%" gradientUnits="userSpaceOnUse"><stop stop-color="#CD2A3E" offset="35%"/><stop stop-color="#BFBFBF" offset="50%"/><stop stop-color="#436F4D" offset="55%"/></linearGradient></defs><text _fill="url(#svgicon_budapest_a)"><tspan font-size="15" x="0" y="70%">Budapest</tspan></text>'
  }
})
