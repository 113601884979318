/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'happy': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 100',
    data: '<defs><path pid="0" d="M81.85 81.85Q95 68.65 95 50q0-18.6-13.15-31.8Q68.65 5 50 5 31.35 5 18.2 18.2 5 31.4 5 50q0 18.65 13.2 31.85Q31.35 95 50 95t31.85-13.15M50 12.65q15.55 0 26.45 10.9 11 11 11 26.45 0 15.55-11 26.5Q65.55 87.45 50 87.45q-15.45 0-26.45-10.95Q12.6 65.55 12.6 50q0-15.45 10.95-26.45 11-10.9 26.45-10.9m-25.7 46q.1 1.25 1.05 2.05 24.55 21.4 49.1 0 .9-.8 1-2.05.05-1.2-.7-2.15-.85-.9-2.05-1-1.25-.05-2.15.75-20.65 18-41.35 0-.9-.8-2.1-.75-1.25.1-2 1-.85.95-.8 2.15m.55-16.5q0 2.55 1.7 4.3 1.85 1.85 4.4 1.85 2.55 0 4.3-1.85 1.85-1.75 1.85-4.3 0-2.6-1.85-4.4-1.75-1.7-4.3-1.7t-4.4 1.7q-1.7 1.8-1.7 4.4M69 48.3q2.55 0 4.3-1.85 1.85-1.75 1.85-4.3 0-2.6-1.85-4.4-1.75-1.7-4.3-1.7t-4.4 1.7q-1.7 1.8-1.7 4.4 0 2.55 1.7 4.3 1.85 1.85 4.4 1.85z" id="svgicon_happy_a"/></defs><use xlink:href="#svgicon_happy_a"/><path pid="1" d="M46.14 86.96c-12.17-.93-23.97-9.2-29.9-20.98-2.92-5.81-4.12-15.27-2.85-22.55 2.46-14.07 13.14-25.59 27.37-29.54 3.82-1.06 14.22-1.07 18.42-.02 11.18 2.8 21.46 11.93 25.65 22.81 3.5 9.08 3.1 20.31-1.04 29.06a33.42 33.42 0 0 1-6.67 9.67c-8.2 8.75-18.29 12.52-30.98 11.55zm11.41-16.48c4.88-1.22 12.77-5.66 16.8-9.43 1.58-1.5 1.6-3.69.04-4.92-1.45-1.14-2.25-1.1-4.01.16-5.5 3.97-8.83 5.95-11.9 7.12-3.25 1.24-3.92 1.34-8.6 1.34-4.54 0-5.43-.13-8.2-1.18-3.8-1.44-6.54-3.02-10.5-6.02-1.66-1.26-3.4-2.3-3.86-2.3-1.51 0-3.08 1.61-3.08 3.16 0 1.62 1.82 3.51 6.3 6.56 8.97 6.09 17.67 7.86 27.01 5.51zM34 47.52c2.27-1.55 3.17-3.08 3.17-5.4 0-2.22-.82-3.7-2.92-5.27-1.6-1.2-5.29-1.18-6.93.02-2.66 1.95-3.4 6.4-1.51 8.99 1.88 2.57 5.7 3.35 8.19 1.66zm38.13 0c2.29-1.55 3.06-2.92 3.06-5.4 0-3.71-2.48-6.16-6.22-6.12-3.77.03-6.16 2.42-6.16 6.16 0 4.83 5.47 7.98 9.32 5.36z" _fill="#fd5"/>'
  }
})
