<script>
import windowScrollPosition from '../mixins.js'

export default {
  name: "topbar",

  mixins: [
    windowScrollPosition('scrollpos')
  ],

  data: () => ({
    isMenuActive: false,
  }),

  computed: {
    scrolled() {
      return this.scrollpos[1] > 80
    }
  },
}
</script>