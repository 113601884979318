/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  '500': {
    width: 16,
    height: 16,
    viewBox: '0 0 48 60',
    data: '<path pid="0" d="M47 5H1a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h46a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zm-1 2v8H2V7zM2 41V17h44v24zm3-30a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H6a1 1 0 0 1-1-1zm8 0a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1zm8 0a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1zm-3.21 16.71l-.79-.8-.79.8a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42l.8-.79-.8-.79a1 1 0 0 1 1.42-1.42l.79.8.79-.8a1 1 0 0 1 1.42 1.42l-.8.79.8.79a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0zm15.42-1.42a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0l-.79-.8-.79.8a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42l.8-.79-.8-.79a1 1 0 0 1 1.42-1.42l.79.8.79-.8a1 1 0 0 1 1.42 1.42l-.8.79zm-2.29 9.06A1 1 0 0 1 30 36a1.12 1.12 0 0 1-.35-.06A16.1 16.1 0 0 0 24 35a16.1 16.1 0 0 0-5.63.94 1 1 0 0 1-.7-1.88A18.43 18.43 0 0 1 24 33a18.43 18.43 0 0 1 6.33 1.06 1 1 0 0 1 .59 1.29z"/>'
  }
})
