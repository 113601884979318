/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  '404': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.375 21.719H1.625c-.827 0-1.5-.673-1.5-1.5V3.781c0-.827.673-1.5 1.5-1.5h20.75c.827 0 1.5.673 1.5 1.5v16.438c0 .827-.673 1.5-1.5 1.5zM1.625 3.281c-.275 0-.5.225-.5.5v16.438c0 .275.225.5.5.5h20.75c.275 0 .5-.225.5-.5V3.781c0-.275-.225-.5-.5-.5H1.625z"/><path pid="1" d="M.625 7.906h22.75v1H.625z"/><circle pid="2" cx="3.002" cy="5.621" r="1.255"/><circle pid="3" cx="6.002" cy="5.621" r="1.255"/><circle pid="4" cx="9.002" cy="5.621" r="1.255"/><path pid="5" d="M22.252 5.61c0 .699-.567 1.266-1.266 1.266h-7a1.266 1.266 0 0 1 0-2.532h7c.699 0 1.266.567 1.266 1.266zM9.37 15.337h.537v.732H9.37v1.028h-.947v-1.028H6.491l-.042-.569 1.965-3.139h.956v2.976zm-2.037 0h1.09v-1.708l-.02-.007-.075.133-.995 1.582zm6.266-.085c0 .627-.144 1.102-.431 1.426-.287.324-.676.486-1.166.486-.494 0-.886-.162-1.174-.486-.288-.324-.433-.8-.433-1.426v-1.044c0-.625.144-1.1.431-1.426.287-.326.677-.49 1.169-.49.49 0 .88.163 1.169.49.29.326.434.802.434 1.426v1.044zm-.947-1.143c0-.375-.056-.65-.169-.824a.545.545 0 0 0-.488-.262.543.543 0 0 0-.488.26c-.111.173-.166.448-.166.825v1.232c0 .381.056.659.168.832a.55.55 0 0 0 .493.26.54.54 0 0 0 .485-.26c.111-.173.166-.451.166-.832v-1.231zm4.362 1.228h.537v.732h-.537v1.028h-.947v-1.028h-1.932l-.042-.569 1.965-3.139h.956v2.976zm-2.036 0h1.09v-1.708l-.02-.007-.075.133-.995 1.582z"/>'
  }
})
