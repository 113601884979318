/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gallery': {
    width: 16,
    height: 16,
    viewBox: '0 0 92 92',
    data: '<path pid="0" d="M91.7 25.4l-9.5 45.8c-.3 1.4-1.5 2.4-2.9 2.4-.2 0-.4 0-.6-.1-1.6-.3-2.7-1.9-2.3-3.5l8.9-42.8-57.4-11.7-1.4 6.4c-.4 1.6-2 2.6-3.6 2.3-1.6-.4-2.6-2-2.3-3.6l2.1-9.3c.4-1.6 1.9-2.6 3.5-2.3l63.2 12.8c.8.2 1.5.6 1.9 1.3.4.7.5 1.5.4 2.3zM73 80c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4V31c0-2.2 1.8-4 4-4h65c2.2 0 4 1.8 4 4v49zM8 35v24.7L24.9 46c1.3-1.1 3.4-1.1 4.7.1l19 16.8 7.9-6.8c1.3-1.1 2.8-1.1 4.1 0l3.3 3.4V35H8zm56 41v-7.3l-5.6-5.3-7.6 6.8a3.48 3.48 0 0 1-4.5 0L27 53.3 8 68.8V76h56zM47.4 51.3c3.4 0 6.1-2.7 6.1-6.1 0-3.4-2.8-6.1-6.1-6.1-3.4 0-6.1 2.7-6.1 6.1-.1 3.3 2.7 6.1 6.1 6.1z"/>'
  }
})
