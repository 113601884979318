<template>
  <vueper-slides
      ref="productslider"
      fixed-height="250px"
      :infinite="true"
      :dragging-distance="80"
      :autoplay="true"
      :transition-speed="1000"
      :visible-slides="3"
      :slide-multiple="true"
      :breakpoints="breakpoints"
      :slide-image-inside="true"
      class="no-shadow">
    <vueper-slide v-for="(slide, i) in slides" :key="i">
      <template v-slot:content>
        <div :style="`background-image: url('${slide.image}');`">
          <button @click="open_gallery(i)"></button>
        </div>
      </template>
    </vueper-slide>
  </vueper-slides>
</template>

<script>
export default {
  name: "product-slider",

  data: () => ({
    slides: [],

    breakpoints: {
      1024: {
        visibleSlides: 2,
        arrows: false,
        fixedHeight: false,
      }
    },
  }),

  methods: {
    open_gallery(index) {
      this.$root.$emit('open-gallery', index)
    },
  },

  mounted() {
    // Load photos
    var d = JSON.parse(document.getElementById('json_data').textContent)
    this.slides = d.product_photos
    this.$refs.productslider.resumeAutoplay()
  },
}
</script>