/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wink': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 100',
    data: '<defs><path pid="0" d="M81.8 81.8Q95 68.65 95 50T81.8 18.15Q68.65 5 50 5T18.15 18.15Q5 31.35 5 50q0 18.65 13.15 31.8Q31.35 95 50 95q18.65 0 31.8-13.2M50 12.6q15.5 0 26.45 10.95T87.4 50q0 15.5-10.95 26.45-10.95 11-26.45 11t-26.5-11Q12.6 65.5 12.6 50t10.9-26.45Q34.5 12.6 50 12.6M27.55 57.05q-.85.85-.85 2.15.05 10.65 6.6 16.15l.05.05q5.9 5.15 16.95 5.15 10.55 0 16.55-5.3 6.6-5.65 6.65-16.05 0-1.3-.9-2.15-.85-.9-2.1-.9H29.65q-1.2 0-2.1.9M25.6 40.7q-.75.9-.6 2.15.1 1.2 1.1 2 .9.75 2.1.65l7.6-.8q1.2-.15 1.95-1.1.8-.95.7-2.2-.15-1.2-1.1-2-.95-.75-2.2-.6l-7.55.85q-1.2.1-2 1.05m47.65 5.7q1.85-1.7 1.85-4.25 0-2.65-1.85-4.4Q71.55 36 69 36t-4.4 1.75q-1.75 1.75-1.75 4.4 0 2.55 1.75 4.25 1.85 1.85 4.4 1.85 2.55 0 4.25-1.85z" id="svgicon_wink_a"/></defs><use xlink:href="#svgicon_wink_a"/><path pid="1" d="M46.8 87.3c-6.14-.6-10.98-2.14-15.74-4.98a40.82 40.82 0 0 1-10.4-9.14c-4.53-5.65-7-11.7-7.83-19.19a61.31 61.31 0 0 1 0-8 36.22 36.22 0 0 1 4.68-14.63 39.21 39.21 0 0 1 15.87-14.9 35.37 35.37 0 0 1 11.66-3.52c2.02-.27 6.53-.32 8.7-.12 9.4.9 17.4 4.94 23.9 12.07a35.53 35.53 0 0 1 9.43 20.16c.23 1.75.26 7.76.05 9.47a35.78 35.78 0 0 1-10.3 21.44C70.6 82.37 63.53 85.98 55 87.11c-1.25.16-7.08.3-8.2.19zm6.61-6.83c4.86-.44 8.85-1.77 11.97-4a24.48 24.48 0 0 0 3.81-3.56c2.46-3 3.85-6.77 4.25-11.47.15-1.82.1-3.02-.16-3.53a4.47 4.47 0 0 0-1.49-1.52l-.42-.23H28.78l-.42.23a3.7 3.7 0 0 0-1.45 1.51c-.23.45-.24.61-.22 2.02.06 3.8.78 6.95 2.25 9.9 1.2 2.43 2.7 4.28 4.88 6.03 3.3 2.65 7.92 4.24 13.56 4.66 1.15.08 4.92.06 6.03-.04zm17.05-32.36a5.62 5.62 0 0 0 2.82-1.69 5.47 5.47 0 0 0 1.82-4.66 5.74 5.74 0 0 0-2-4.23A5.7 5.7 0 0 0 69.02 36a5.7 5.7 0 0 0-2.88.65A5.84 5.84 0 0 0 63 40.48c-.2.83-.2 2.45 0 3.27a6.4 6.4 0 0 0 4.46 4.35c.83.2 2.16.2 3 0zM32.1 45.15c4.55-.48 4.66-.5 5.45-1.3a2.96 2.96 0 0 0 .09-4.2c-.5-.5-1.06-.8-1.7-.88-.6-.07-8.2.76-9 .99-.66.18-1.4.8-1.75 1.46-.33.62-.32 1.93.02 2.62.28.58.92 1.2 1.54 1.47.61.28 1.25.26 5.35-.16z" _fill="#fd5"/>'
  }
})
