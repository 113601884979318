/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'partner': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 100',
    data: '<switch><g><path pid="0" d="M87.3 53.3l-4-1.8-1.2-.6c2.4-3 3.9-7 3.9-11.4 0-9.3-6.7-17-14.9-17s-14.9 7.6-14.9 17c0 4.4 1.5 8.4 3.9 11.4l-1.2.6-4 1.8c-.5.2-1 .5-1.5.8-1.8-3.2-4.6-5.9-8-7.4l-4-1.8-1.2-.6c2.4-3 3.9-7 3.9-11.4 0-9.3-6.7-17-14.9-17S14 23.4 14 32.8c0 4.4 1.5 8.4 3.9 11.4l-1.2.6-4 1.8c-6.2 2.8-10.2 9-10.2 15.8v7.1c0 4.4 3.6 8.1 8.1 8.1h34.1c.7 3.8 4 6.7 7.9 6.7h36.8c4.4 0 8.1-3.6 8.1-8.1v-7.1c0-6.8-4-13-10.2-15.8zM71 27.9c5.2 0 9.5 5.2 9.5 11.5S76.3 51 71 51s-9.5-5.2-9.5-11.5 4.3-11.6 9.5-11.6zm-42-6.6c5.2 0 9.5 5.2 9.5 11.5S34.2 44.3 29 44.3s-9.5-5.2-9.5-11.5 4.2-11.5 9.5-11.5zM7.9 69.5v-7.1c0-4.7 2.8-8.9 7-10.9l4-1.8c1.1-.5 2.2-1.1 3.2-1.9 2 1.2 4.4 1.9 6.8 1.9 2.5 0 4.8-.7 6.8-1.9 1 .7 2.1 1.4 3.2 1.9l4 1.8c2.7 1.2 4.8 3.4 6 6-2.8 3.1-4.4 7.2-4.4 11.5v3h-34c-1.4.1-2.6-1.1-2.6-2.5zm84.2 6.6c0 1.4-1.2 2.6-2.6 2.6H52.6c-1.4 0-2.6-1.2-2.6-2.6V69c0-4.7 2.8-8.9 7-10.9l4-1.8c1.1-.5 2.2-1.1 3.2-1.9 2 1.2 4.4 1.9 6.8 1.9 2.5 0 4.8-.7 6.8-1.9 1 .7 2.1 1.4 3.2 1.9l4 1.8c4.3 1.9 7 6.2 7 10.9v7.1z"/></g></switch>'
  }
})
