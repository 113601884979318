<template>
  <div class="notification announcement" v-if="showAnnoucement">
    <button class="delete" @click="close"></button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "announcement",

  props: {
    id: Number,
    date: Number,
  },

  data: () => ({
    closingClicks: 0,
    closed: false,
  }),

  computed: {
    showAnnoucement() {
      return !this.closed && this.closingClicks < 2
    },

    name() {
      return `announcement_${this.id}_${this.date}`
    }
  },

  mounted() {
    this.closingClicks = this.$ls.get(this.name, 0)
  },

  methods: {
    close() {
      this.closed = true
      this.closingClicks += 1
      this.$ls.set(this.name, this.closingClicks, 7*24*60*60*1000)
    }
  },
}
</script>