<template>
  <vue-image-lightbox ref="products"
    :media="media" :show-thumbs="false" :show-caption="true" :show-light-box="false">
  </vue-image-lightbox>
</template>

<script>
export default {
  name: "product-photos",

  data: () => ({
    media: [],
  }),

  mounted() {
    // Load photos
    var d = JSON.parse(document.getElementById('json_data').textContent)
    this.media = d.product_photos.map(item => {
      return {
        src: item.image,
        caption: item.title
      }
    })

    // Open gallery on 'open-gallery' event
    this.$root.$on('open-gallery', (index) => {
      this.$refs['products'].showImage(index);
    });
  },
}
</script>