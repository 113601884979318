/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'location': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 125',
    data: '<path pid="0" d="M0 77.52a36.36 36.36 0 0 1 1.87-4.43 16.86 16.86 0 0 1 5.86-5.62 50.4 50.4 0 0 1 16.54-6.35 4 4 0 0 1 4.76 3 4.13 4.13 0 0 1-2.83 5c-2.53.81-5.12 1.46-7.62 2.35a22.54 22.54 0 0 0-8.93 5.19c-1.65 1.76-1.7 3.16-.07 4.96a17.4 17.4 0 0 0 5.89 3.9 65.2 65.2 0 0 0 16.62 4.71 110.05 110.05 0 0 0 22.06 1.33 90.31 90.31 0 0 0 25.77-4.23 29.17 29.17 0 0 0 9.67-4.91c2.56-2.14 2.67-3.83.28-6.16a21.74 21.74 0 0 0-8.07-4.6c-2.68-1-5.45-1.68-8.17-2.55a4.14 4.14 0 0 1-.28-7.74 4.12 4.12 0 0 1 2.17-.26c6.39 1.38 12.54 3.39 17.94 7.21a15 15 0 0 1 5.71 6.76c1.8 4.67.58 8.73-2.72 12.29a27.13 27.13 0 0 1-9.29 6.14A76.07 76.07 0 0 1 67 98.82a120.42 120.42 0 0 1-20.92 1.1c-10.17-.34-20.2-1.6-29.85-5-4.55-1.63-8.89-3.69-12.38-7.17a12.2 12.2 0 0 1-3.7-6.86.81.81 0 0 0-.15-.24v-3.13z"/><path pid="1" d="M52.53 0c1.84.39 3.7.67 5.51 1.18A28.44 28.44 0 0 1 73.89 12.5 28.26 28.26 0 0 1 79 26.42a29.47 29.47 0 0 1-4.68 18.84c-4.43 7-8.85 14.1-13 21.28-2.65 4.54-4.9 9.31-7.31 14a4.4 4.4 0 0 1-3.42 2.72 4 4 0 0 1-3.86-1.56 8.42 8.42 0 0 1-.84-1.43c-4.43-8.79-8.79-17.62-14.06-26-1.63-2.58-3.36-5.11-5.14-7.59a29.41 29.41 0 0 1-5.5-13.07 28.35 28.35 0 0 1 5.49-21.9A28.85 28.85 0 0 1 44.58.51C45.66.29 46.75.17 47.84 0h4.69zM50 41.62a12.46 12.46 0 1 0-12.51-12.41A12.5 12.5 0 0 0 50 41.62z"/><path pid="2" d="M54.15 29.21a4.15 4.15 0 1 1-8.3-.14 4.15 4.15 0 0 1 8.3.14z"/>'
  }
})
