/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'flag-hu': {
    width: 16,
    height: 16,
    viewBox: '0 0 640 480',
    data: '<g fill-rule="evenodd"><path pid="0" _fill="#fff" d="M640 480H0V0h640z"/><path pid="1" _fill="#388d00" d="M640 480H0V320h640z"/><path pid="2" _fill="#d43516" d="M640 160.1H0V.1h640z"/></g>'
  }
})
