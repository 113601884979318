/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gift': {
    width: 16,
    height: 16,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8.19 47.22h29.09a3.83 3.83 0 0 0 3.83-3.83V24.84a3.82 3.82 0 0 0 3.44-3.78v-6.39a3.83 3.83 0 0 0-3.82-3.82h-7.28a6.53 6.53 0 0 0 2.36-4.88A6.02 6.02 0 0 0 29.62 0c-3.63 0-5.7 2.68-6.89 5.54C21.55 2.68 19.48 0 15.84 0a6.02 6.02 0 0 0-6.19 5.97c0 1.82.86 3.54 2.36 4.88H4.74A3.83 3.83 0 0 0 .9 14.68v6.38c0 1.98 1.52 3.6 3.45 3.79v18.54a3.83 3.83 0 0 0 3.83 3.83zm-.77-3.83v-18.5H21.2v19.27h-13a.77.77 0 0 1-.77-.77zm29.86.76H24.26V24.88h13.79v18.5c0 .43-.35.77-.77.77zm4.21-29.47v6.38c0 .42-.34.76-.77.76H24.27v-7.91h16.47c.42 0 .76.34.76.77zM29.62 3.06a2.99 2.99 0 0 1 3.13 2.92c0 1.13-.69 2.22-1.93 3.05-1.07.71-3 1.55-6.35 1.76.44-2.7 1.72-7.73 5.15-7.73zm-13.78 0c3.43 0 4.71 5.03 5.15 7.73-3.34-.21-5.28-1.05-6.35-1.76-1.24-.83-1.93-1.92-1.93-3.06a3 3 0 0 1 3.13-2.9zm-11.87 18v-6.38c0-.43.35-.77.77-.77H21.2v7.91H4.74a.77.77 0 0 1-.77-.76z"/><path pid="1" d="M24.45 34.46v-9.57h13.43l-.06 9.51-.05 9.51-6.66.06-6.66.05zM7.7 43.88a174 174 0 0 1-.13-9.56v-9.43h13.62v19.12h-6.67c-3.68 0-6.74-.06-6.82-.13z" _fill="#fc0"/><path pid="2" d="M4 21.75a27.4 27.4 0 0 1-.15-3.87c0-3.46.03-3.74.4-3.95.26-.14 3.37-.22 8.67-.22h8.27v8.18h-8.53c-4.7 0-8.6-.06-8.67-.14zM24.26 17.83V13.7h8.32c6.35 0 8.4.06 8.68.27.32.24.35.69.3 4.06l-.06 3.8-8.62.05-8.62.06zM38.02 34.18c0-5.1.01-7.2.02-4.64a1966.03 1966.03 0 0 1-.02 4.64z" _fill="#ff2a2a"/><path pid="3" d="M38.02 34.18c0-1.19 0-1.68.02-1.08a96.1 96.1 0 0 1-.02 1.08z" _fill="#ff2a2a"/><path pid="4" d="M7.8 43.64c-.1-.18-.15-3.41-.15-9.5v-9.2h13.52v18.98h-6.61c-6.39 0-6.62-.01-6.76-.28z" _fill="#ff0"/><path pid="5" d="M24.26 34.54v-9.6h13.97l-.1 5.04c-.05 2.78-.1 7.1-.1 9.61v4.56H24.26zM7.46 44.06c-.07-.1-.14-4.43-.16-9.61l-.05-9.43h13.92v19.22h-6.8c-5.27 0-6.82-.04-6.9-.18z" _fill="#fd5"/>'
  }
})
